import { Error } from "./types";

export function auth(accessToken: string) {
  return {
    type: "auth",
    access_token: accessToken,
  };
}

export function components() {
  return {
    type: "get_components",
    cmd: "get_components",
  };
}

export function config() {
  return {
    type: "get_config",
    cmd: "get_config",
  };
}

export function themes() {
  return {
    type: "get_themes",
    cmd: "get_themes",
  };
}

export function user() {
  return {
    type: "auth/current_user",
    cmd: "auth/current_user",
  };
}

export function scope() {
  return {
    type: "auth/current_scope",
    cmd: "auth/current_scope",
  };
}

export function tenants() {
  return {
    type: "get_tenants",
    cmd: "get_tenants",
  };
}

export function tenant(slug) {
  return {
    type: "get_tenant",
    cmd: "get_tenant",
    slug,
  };
}

type SubscribeEventMessage = {
  type: "subscribe_events";
  cmd: string;
  event_type?: string;
};

export function subscribeEvents(eventType?: string) {
  const message: SubscribeEventMessage = {
    type: "subscribe_events",
    cmd: eventType || "",
  };

  if (eventType) {
    message.event_type = eventType;
  }

  return message;
}

export function unsubscribeEvents(id: number, data: any) {
  return {
    ...data,
    type: "unsubscribe_events",
    cmd: data?.cmd || "",
    id,
  };
}

export function ping() {
  return {
    type: "ping",
    cmd: "ping",
  };
}

export function error(code: Error, message: string) {
  return {
    type: "result",
    success: false,
    error: {
      code,
      message,
    },
  };
}
