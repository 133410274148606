import { Tenant } from "@taui/shared/interfaces/openapi/adminui-api";
import { Connection } from "./connection";
import { getCollection } from "../websocket/collection";
import * as messages from "../websocket/messages";

const getTenants = (connection: Connection) =>
  connection.sendMessagePromise<Tenant[]>(messages.tenants());

export const tenantsCollection = (conn: Connection) =>
  getCollection(
    conn,
    "_tenants",
    () => getTenants(conn) as Promise<Tenant[]>,
    undefined
  );

export const subscribeTenants = (
  conn: Connection,
  onChange: (tenants: Tenant[]) => void
) => tenantsCollection(conn).subscribe(onChange);
