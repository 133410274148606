import { IdentityDTO } from "@taui/shared/interfaces/openapi/identity-api";
import { Connection } from "./connection";
import { getCollection } from "../websocket/collection";
import * as messages from "../websocket/messages";

const getUser = (connection: Connection) =>
  connection.sendMessagePromise<IdentityDTO>(messages.user());

export const userCollection = (conn: Connection) =>
  getCollection(
    conn,
    "_user",
    () => getUser(conn) as Promise<IdentityDTO>,
    undefined
  );

export const subscribeUser = (
  conn: Connection,
  onChange: (user: IdentityDTO) => void
) => userCollection(conn).subscribe(onChange);
