import { Connection } from "./connection";
import { getCollection } from "../websocket/collection";
import * as messages from "../websocket/messages";
import { TauiConfig } from "../types/config";

const getConfig = (connection: Connection) =>
  connection.sendMessagePromise<TauiConfig>(messages.config());

export const configCollection = (conn: Connection) =>
  getCollection(
    conn,
    "_config",
    () => getConfig(conn) as Promise<TauiConfig>,
    undefined
  );

export const subscribeConfig = (
  conn: Connection,
  onChange: (config: TauiConfig) => void
) => configCollection(conn).subscribe(onChange);
