import { Connection } from "./connection";
import { getCollection } from "../websocket/collection";
import * as messages from "../websocket/messages";

const getScopes = (connection: Connection) =>
  connection.sendMessagePromise<string[]>(messages.scope());

export const scopesCollection = (conn: Connection) =>
  getCollection(
    conn,
    "_scopes",
    () => getScopes(conn) as Promise<string[]>,
    undefined
  );

export const subscribeScopes = (
  conn: Connection,
  onChange: (scopes: string[]) => void
) => scopesCollection(conn).subscribe(onChange);
