import { ThemeSettings } from "../types";

export const DEFAULT_THEME = "tucano";

const STORAGE = window.localStorage || {};

export function getLocalSelectedTheme(): ThemeSettings {
  if (STORAGE.tenants) {
    try {
      const stored = JSON.parse(STORAGE.selectedTheme);
      if (stored) {
        return stored;
      }
    } catch (err: any) {
      // Ignore parsing error.
    }
  }

  // Final fallback
  return { theme: DEFAULT_THEME };
}
